angular.module('arounja.directives').directive('searchInput', function () {
	return {
		templateUrl: 'templates/directives/search-form.html',
		restrict: 'E',
		replace: true,
		scope: {
			wrapperclass: '=',
			searchVisible: '=searchvisible',
			displaySuggestions: '=displaysuggestions',
			searchHook: '=searchhook',
			enableFilter: '=enablefilter',
			categoryLandingVisible: '=categorylandingvisible',
			keywordTypeahead: '=keywordtypeahead'
		},
		controller: ['CLIENT_NAME', '$scope', '$log', 'SearchCriteria', 'BackendApi', 'Location', '$state', 'airsTopLevel', 'userInfo', '$http', '$q', 'uiGmapGoogleMapApi', 'Geocode', '$timeout', 'PlacesAutocomplete', function (CLIENT_NAME, $scope, $log, SearchCriteria, BackendApi, Location, $state, airsTopLevel, userInfo, $http, $q, uiGmapGoogleMapApi, Geocode, $timeout, PlacesAutocomplete) {
			$scope.showAirsCount = true;
			$scope.autocompleteVisible = false;
			$scope.visibleDelay = false;
			$scope.customLocation = {
				selected: false,
				value: null
			};
			$scope.userInput = {
				keyword: SearchCriteria.data.keyword,
				location: SearchCriteria.data.locationString || 'Current Location',
				radius: String(SearchCriteria.data.radius)
			};
			$scope.errors = {};
            $scope.airsDictioanry = {};
            $scope.highlightedAirs = null;
            $scope.highlightedResources = null;

			$scope.autoComplete = {
				loading: null,
				noResults: null,
				locationResults: {
					open: false,
					hasResults: null,
					loading: false,
					loadedOnce: false,
					suggestionsContainer: $('.' + $state.current.name.split('.')[1] + ' .typeahead-suggestions--location'),
					openImmediateFlag: false
				},
				locationOpenFlag: false
			};
			if ($state.current.name === 'app.home') {
				$scope.suggestionsAppend = $('.home .search-suggestion .suggestions-container');
			}
			else if ($state.current.name === 'app.results') {
				$scope.suggestionsAppend = $('.results .search-suggestion .suggestions-container');
				$scope.customLocation = {
					selected: true,
					value: $scope.userInput.location
				};
			}

			$scope.searchOpen = function () {
				$scope.searchVisible = true;
			};

            $scope.getAirs = function(parent) {
                BackendApi.getAirs(parent, false, false, '').then(function(result) {
                    $scope.airsDictionary = result.reduce(function(prev, curr) {
                        prev[curr.name] = curr;
                        return prev;
                    }, {});
                });
            };

			$scope.search = function (airId) {

				//clear keyword if a category was selected
				if (!airId) {
					SearchCriteria.data.keyword = $scope.userInput.keyword;
				}

				SearchCriteria.data.radius = $scope.userInput.radius;

				if (typeof airId !== 'undefined') {
					SearchCriteria.data.airs.push(airId);
				}

				if ($scope.userInput.location === 'Current Location') {
					SearchCriteria.data.locationString = 'Current Location';
					$scope.getCurrentLocation().then(function () {
						$scope.searchHook();
					});
				}
				else if ($scope.userInput.location === '') {
					$scope.errors.location = true;
					return;
				}
				else if (!$scope.customLocation.selected || $scope.customLocation.value !== $scope.userInput.location) {
					$scope.errors.location = true;
					return;
				}
				else {
					$scope.searchHook();
				}

			};

            $scope.goToResource = function(resourceId) {
				SearchCriteria.data.radius = $scope.userInput.radius;

				if ($scope.userInput.location === 'Current Location') {
					SearchCriteria.data.locationString = 'Current Location';
					$scope.getCurrentLocation().then(function () {
                        $state.go('app.resource', {
                            resourceId: resourceId
                        }, {});
					});
				}
				else if ($scope.userInput.location === '') {
					$scope.errors.location = true;
					return;
				}
				else if (!$scope.customLocation.selected || $scope.customLocation.value !== $scope.userInput.location) {
					$scope.errors.location = true;
					return;
				}
				else {
                    $state.go('app.resource', {
                        resourceId: resourceId
                    }, {});
				}

            };

            $scope.selectCategory = function(airs) {
                $scope.search(airs.term_id);
            };

			$scope.setLocationQuery = function (location) {
				if (location === 'Current Location') {
					$scope.userInput.location = 'Current Location';
					Location.getLocation().then(function (result) {
						SearchCriteria.data.latitude = result.coords.latitude;
						SearchCriteria.data.longitude = result.coords.longitude;
						SearchCriteria.data.locationString = 'Current Location';
						$scope.errors.location = false;
					});
				}
				else {
					Geocode.getCoords(location).then(function (result) {
						SearchCriteria.data.latitude = result.latitude;
						SearchCriteria.data.longitude = result.longitude;
						$scope.userInput.location = result.formatted_address;
						SearchCriteria.data.locationString = result.formatted_address;
						$scope.errors.location = false;
						$scope.customLocation.selected = true;
						$scope.customLocation.value = result.formatted_address;
					});
				}
			};

			$scope.getCurrentLocation = function () {
				var deferred = $q.defer();
				Location.getLocation().then(function (result) {
					SearchCriteria.data.latitude = result.coords.latitude;
					SearchCriteria.data.longitude = result.coords.longitude;
					deferred.resolve(result);
				});
				return deferred.promise;
			};

			$scope.getLocation = function (val) {
				var deferred = $q.defer();
				PlacesAutocomplete.getPlace(val).then(function (results) {
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			$scope.toggleSuggestionsPane = function (type) {
				$timeout(function () {
                    type.openImmediateFlag = !type.openImmediateFlag;
					type.open = !type.open;
					type.hasResults = !type.hasResults;
				}, 200);
			};

			$scope.getAirsSuggestions = function (keyword) {
				var deferred = $q.defer();
				BackendApi.getAirs(-1, true, false, keyword, 'count').then(function (results) {
					deferred.resolve(results);
				});

				return deferred.promise;

			};

			$scope.airsSelect = function (air) {
				$scope.search(air.term_id);
			};

			$scope.init = function () {
				// $scope.getCurrentLocation();
				if (CLIENT_NAME === 'chemung') {
					$scope.setLocationQuery('Elmira, NY, USA');
				}
                if ($scope.categoryLandingVisible) {
                    $scope.getAirs(0);
                    Location.getLocation().then(function(result) {
                        BackendApi.getHighlightedAirsCategory().then(function(cat) {
                            $scope.highlightedAirs = cat;
                            var searchData = {
                                latitude: result.coords.latitude,
                                longitude: result.coords.longitude,
                                radius: 25,
                                airs: [cat.term_id],
                                highlighted: true
                            };
                            BackendApi.getResources(searchData).then(function(ret) {
                                $scope.highlightedResources = ret.items;
                            });
                        });
                    });
                }
			};

			$scope.init();
		}]
	};
});
