angular.module('arounja.controllers', [])
    .controller('AppCtrl', ['$scope', '$ionicModal', '$timeout', '$log', 'Authentication', '$location', '$window', '$state', 'Organization', 'BackendApi', 'Analytics', 'DevTools', function($scope, $ionicModal, $timeout, $log, Authentication, $location, $window, $state, Organization, BackendApi, Analytics, DevTools) {
        $scope.DevTools = DevTools;

        $scope.Analytics = Analytics;

        $scope.open = function(url, absolute) {
            if(!absolute){
                if (url[0] === '/') {
                    url = dynamicRoot + url.substring(1);
                } else if (url.substring(0, 4) !== 'http'){
                    url = dynamicRoot + url;
                }

                if (!/^https?:\/\//i.test(url)) {
                    url = 'http://' + url;
                }
            } else if (url.substring(0, 4) !== 'http') {
                url = 'http://' + url;
            }

            //if (!angular.equals(ionic.Platform.device(), {})) {
                $window.open(url, '_system');
            //} else {
            //    $window.open(url, \'_blank\');
            //}
        };

        $scope.isLoggedIn = Authentication.getLoginStatus();

        $scope.logout = Authentication.logout;

        if($scope.isLoggedIn){
            Analytics.set('&uid', Authentication.getUserName());
            Analytics.set('userId', Authentication.getUserName());
        }

    }]);
