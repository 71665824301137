angular.module('arounja.services').factory('SearchCriteria', ['$log', '$q', 'Analytics', 'Airs', 'CLIENT_NAME',
    function($log, $q, Analytics, Airs, CLIENT_NAME) {
        var f = {};
        f.data = {};
        f.setDefaults = function() {
            this.data = {
                keyword: null,
                latitude: null,
                longitude: null,
                radius: this.data.radius || 25,
                airs: [],
                locationString: null
            };
        };

        f.saveToSession = function() {
            sessionStorage.setItem('SearchCriteria', JSON.stringify(this.data));
        };

        f.restoreFromSession = function() {
            var savedData = sessionStorage.getItem('SearchCriteria');
            if (savedData) {
                this.data = JSON.parse(savedData);
            } else {
                this.setDefaults();
            }
        };

        f.mockData = function() {
            f.data.radius = f.data.radius || 25;
            f.data.latitude = f.data.latitude || 42.9484557;
            f.data.longitude = f.data.longitude || -78.8495779;
            f.data.airs = f.data.airs && f.data.airs.length ? f.data.airs : [];
        };

        f.trackSearch = function (){
            var query = '';
            var d = d || this.data;

            if(d.keyword){
                query += 'q=' + d.keyword;
            }

            if(d.airs && d.airs.length > 0){
                for(var i in d.airs){
                    for(var j in Airs){
                        if(d.airs[i] === Airs[j].term_id){
                            if(query !== '') query+= '&';
                            query+= 'airs=' + encodeURI(Airs[j].name);
                            break;
                        }
                    };
                }
            }

            // if(d.airsDetailed && d.airsDetailed.length > 0){
            //     for(var i in d.airsDetailed){
            //         if(query !== '') query+= '&';
            //         query+= 'airs=' + encodeURI(d.airsDetailed[i].name);
            //     }
            // }

            if(query != '')
                query = '?' + query;

            query = '/app/search/' + query;

            Analytics.trackPage( query );
        }

        return f;
    }
  ]);
